
import { Vue, Component } from "vue-property-decorator";
import { CertificateTemplateListDataSource } from "@/data/List/CertificateTemplateListDataSource";
import {FolderDataSource} from "@/data/Tree/FolderDataSource";
@Component
export default class CertificateTemplates extends Vue {
  dataSource: CertificateTemplateListDataSource =
    new CertificateTemplateListDataSource();
  $message: any;

  folderDataSource: FolderDataSource = new FolderDataSource({
    className: "CertificateTemplateFolder",
    rootFolder: "mainFolder",
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Шаблоны сертификатов",
        disabled: true,
      },
    ];
  }

  async copy(item: any) {
    await this.dataSource.copy(item.id);
    this.$message("Шаблон успешно скопирован");
  }
}
