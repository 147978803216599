import axios from "axios";
import { ListDataSource } from "./ListDataSource";

export class CertificateTemplateListDataSource extends ListDataSource {
  constructor() {
    super({
      config: {
        pageIndex: 1,
        pageSize: 20,
      },
      className: "certificatetemplate",
    });
  }

  public async copy(id: number) {
    const idx = this.items.findIndex((i: any) => i.id == id);
    if (idx === -1) throw new Error("Элемент не найден");

    try {
      const { data } = await axios.post(`${this.baseUrl}/${id}/copy/`);
      this.items.unshift(data);
      await this.changePriority();

      return data;
    } catch (error) {
      throw error;
    }
  }
}
